import router from "@/router";
import L from 'leaflet';
export default {
    urlAPI: 'https://apiv2.mobixgroup.com',
    urlAPP: 'https://diday.pro',
    urlSocket: 'https://socket.mobixgroup.com',
    key: '5ftetxVm9GCaJ9j7wH2G96j676EnSu34XuR9mEESjUq5Y4M584S5mzV',    
    user: null,
    history: [],
    schema: {},
    params: false,
    logo:'media/logos/logo-diday.png',
    txt:{},
    lang: localStorage.lang === undefined ? 'fr' : localStorage.lang,    
    ajax(route, params = null, callBack) {
        if(route === undefined){
            return false;
        }
        const url = route.search('http') === 0 ? route : this.urlAPI + route;
        let the = this;
        let option = {};
        option.headers = {
          "Content-Type":"application/json",
          Authorization: this.getToken()
        }
        if(params != null){
          option.method = "POST",
          option.body = JSON.stringify(params)
        }
        fetch(url,option).then( response => {
          if(response.status === 401){
            the.destroyToken();
            the.destroyUser();
            //router.push({path:'/login'})
            if(router.currentRoute.name === 'login' || router.currentRoute.name === null){
              return false;
            }else{
              router.push({path:'/login'})
            }
          }
          response.json().then( data =>{
            the.res = data;
            if(data.status === false){
              this.toast.toast(data.data, {
                title: 'Erreur',
                variant: 'danger',
                solid: true
              });
            }
            if(callBack != null) {
              callBack(the.res);
            }
          })
        }).catch( res => {
          console.log(res);
        });
    },  
    getToken(){
        var t = window.localStorage.getItem("token");
        if(t === undefined || t === null){
            return false;
        }
        return t;
    },
    saveToken(token){
        window.localStorage.setItem("token", token);
    },
    saveUser(user){
        this.user = user;
    },
    getUser(){
        return this.user;
    },
    destroyToken(){
        window.localStorage.removeItem("token");
    },
    destroyUser(){
        this.user = null;
    },
    timestampToDate(timestamp,datetime = true){
        var date = new Date(timestamp*1000);
        var res = date.toLocaleDateString("fr-Fr");
        if(datetime){
          res += ' '+date.toLocaleTimeString("fr-Fr");
        }
        return res;
    },
    now(){
        var n = new Date().getTime();
        n = n/1000;
        return parseInt(n);
    },
    getText(key){
        var txt = this.txt[this.lang][key];
        if(txt === undefined){
          return key;
          //return '';
        }else{
          return txt;
        }
    },
    getSchema(name){
        var check = this.schema[name];
        if(check === undefined){
          return {}
        }
        return JSON.parse(JSON.stringify(check));
    },
    initSchema(){
        if(this.schema.UserRole === undefined){
            this.ajax('/magic/schema', null, (res) => {
                if(res.status){
                    this.schema = res.data
                }
            })
        }
    },
    getParamTitre(array,ext_val){
        var check = array.find(x => x.id === ext_val);
        if(check === undefined){
            return "Aucun";
        }else{
            return this.getText(check.titre);
        }
    },
    getPrice(demenagement,tva = false,formule = false, user = this.user){
        var last = 'v3';
        var version = demenagement.deposeur.franchise;
        version = version === undefined ? last : version;
        return this[version](demenagement, tva, formule, user);
    },
    v1(demenagement,tva = false,formule = false, user){
        var franchise = 0;
        var franchisemax = 0;
        if(demenagement.lot.suppfranch == true){
            var assurance = ( parseFloat(demenagement.lot.montant_assurer)*0.6) /100;
            franchisemax = (parseInt(demenagement.lot.prix_dailycall_ht)*8)/100;
            if(franchisemax < assurance ){
                franchise = franchisemax;
            }else{
                franchise = assurance;
            }
        }
        if(formule === true){
            return franchise;
        }
        var supplement = demenagement.lot.supplement === "" || demenagement.lot.supplement === null ? 0 : parseFloat(demenagement.lot.supplement);
        var priceHT = parseFloat(demenagement.lot.prix_dailycall_ht) - parseInt(franchise) + supplement;
        if(user.secteur_id != demenagement.secteur_id){
            return priceHT.toFixed(2);
        }
        if(tva === false){
            return priceHT.toFixed(2)
        }else{
            if(demenagement.lot.exo_tva == true){
                return priceHT.toFixed(2);
            }else{
                var ratioTVA = (100+20)/100;
                return (priceHT*ratioTVA).toFixed(2);
            }
        }
    },
    v2(demenagement,tva = false,formule = false, user){
        var franchise = 0;
        var franchisemax = 0;
        if(demenagement.lot.suppfranch == true){
            var assurance = ( parseFloat(demenagement.lot.montant_assurer)*0.6) /100;//45
            franchisemax = (parseInt(demenagement.lot.prix_dailycall_ht)*8)/100;
            if(franchisemax < assurance ){
                franchise = franchisemax;
            }else{
                franchise = assurance;
            }
        }
        if(parseInt(demenagement.lot.prix_dailycall_ht) <= 400){
            franchise = 0
        }
        if(formule === true){
            return franchise;
        }
        var supplement = demenagement.lot.supplement === "" || demenagement.lot.supplement === null ? 0 : parseFloat(demenagement.lot.supplement);
        var priceHT = parseFloat(demenagement.lot.prix_dailycall_ht) - parseInt(franchise) + supplement;
        if(demenagement.deposeur.type_id === 2){
            return demenagement.lot.prix_dailycall_ht;
        }
        if(user.secteur_id != demenagement.secteur_id){
            return priceHT.toFixed(2);
        }    
        if(tva === false){
            return priceHT.toFixed(2)
        }else{
            if(demenagement.lot.exo_tva == true){
                return priceHT.toFixed(2);
            }else{
                var ratioTVA = (100+20)/100;
                return (priceHT*ratioTVA).toFixed(2);
            }
        }
    },
    v3(demenagement,tva = false,formule = false, user){
        var franchise = 0;        
        if(demenagement.lot.suppfranch == true){
            var prixBase = demenagement.lot.prix_dailycall_ht;
            if(prixBase > 0){
                if(prixBase <= 400){
                    franchise = 35;
                }else if(prixBase >= 401 && prixBase <= 800){
                    franchise = 45;
                }else if(prixBase >= 801 && prixBase <= 1600){
                    franchise = 65;
                }else {
                    franchise = 80;
                }
                franchise += 25;
            }
        }                
        if(formule === true){
            return franchise;
        }
        var supplement = demenagement.lot.supplement === "" || demenagement.lot.supplement === null ? 0 : parseFloat(demenagement.lot.supplement);
        var priceHT = parseFloat(demenagement.lot.prix_dailycall_ht) - parseInt(franchise) + supplement;
        // if(demenagement.deposeur.type_id === 2){
        //     return demenagement.lot.prix_dailycall_ht;
        // }
        if(user.secteur_id != demenagement.secteur_id){
            return priceHT.toFixed(2);
        }    
        if(tva === false){
            return priceHT.toFixed(2)
        }else{
            if(demenagement.lot.exo_tva == true){
                return priceHT.toFixed(2);
            }else{
                var ratioTVA = (100+20)/100;
                return (priceHT*ratioTVA).toFixed(2);
            }
        }
    },
    changeLang(code){
        var lang = null;
        lang = code;
        if([null,''].includes(lang)){
            lang = 'fr';
        }
        window.localStorage.setItem("lang", lang);
        location.reload();
    },
    checkAcl(id){
    var check = this.user.acl.find(a => a === id);
    if(check === undefined){
        return false;
    }else{
        return true;
    }
    },
    q(param, id){
        if(this.params[param] === undefined){
            return {}
        }
        var check = this.params[param].find(p => p.id === id);
        if(check === undefined){
            return {}
        }
        return check
    },
    t(word){
        return this.getText(word);
    },
    p(v){
        if([null, undefined, ''].includes(v)){
            return this.p(0);
        }
        return v.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        });
    },
    changeService(id){
        if(this.user.current_service === id){
            return false;
        }
        var params = {
            service_id:id
        }
        this.ajax('/user/service', params, res => {
            if(res.status === true){
                location.href = "/";
            }
        })
    },
    target(){
        return this.ifPWA() ? '_self' : '_blank';
    },
    ifPWA(){
        return window.matchMedia('(display-mode: standalone)').matches
    },
    icon(color = 'primary'){
        return L.divIcon({
            className: 'custom-div-icon',
            html: `<i class="fas fa-map-marker fa-3x text-${color}"></i>`,
            iconSize: [16, 37],
            iconAnchor: [10, 28]
        });
    },
}
